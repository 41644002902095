
import { defineComponent, inject, ref } from "vue";
import { ImageData } from "coho-ui";
import server from "../server";
import { Database } from "vuebase";
import { Upload } from "../main";
import { CachePolicy } from "vuebase/src/storage";

export default defineComponent({
    name: "Upload",
    setup() {
        const database = inject(Database.InjectionKey);
        const imagePicker = ref();
        const uploadComplete = ref(false);
        const upload = ref(
            database?.collection<Upload>("uploads").new(undefined, {
                image: undefined,
                email: "",
                notes: "",
                dateUploaded:
                    Database.firebase.firestore.FieldValue.serverTimestamp() as Database.firebase.firestore.Timestamp,
            }),
        );

        function uploadImage(file: File, progressCallback: (progress: number) => void): Promise<ImageData | void> {
            return server.uploadImage(
                file,
                "uploads",
                progressCallback,
                CachePolicy.Day,
                { width: 1200, height: 1200 },
                100,
            );
        }

        function saveUpload(): Promise<void> {
            if (upload.value) {
                imagePicker.value.applyCrop();
                return upload.value.save().then(() => {
                    uploadComplete.value = true;
                });
            }
            return Promise.reject();
        }

        return {
            upload,
            uploadComplete,
            imagePicker,
            uploadImage,
            saveUpload,
        };
    },
});
