<template>
    <c-panel>
        <div class="flex f-justify-center mt-5" v-if="upload && upload.data">
            <c-grid class="widths-100-all" style="max-width: 600px">
                <c-grid-item>
                    <c-image-picker
                        ref="imagePicker"
                        v-model="upload.data.image"
                        :upload="uploadImage"
                    ></c-image-picker>
                </c-grid-item>
                <c-grid-item>
                    <c-grid class="widths-100-all">
                        <c-grid-item>
                            <c-textbox label="Email" v-model="upload.data.email" />
                        </c-grid-item>
                        <c-grid-item>
                            <c-textarea label="Notes" :lines="5" v-model="upload.data.notes" />
                        </c-grid-item>
                        <c-grid-item>
                            <c-button-group class="flex f-justify-end" v-if="!uploadComplete">
                                <c-button class="mt-5" @click="saveUpload()">Save</c-button>
                            </c-button-group>
                            <div class="flex f-column f-align-center f-justify-center mt-5" v-else>
                                <div class="success">Your image has been successfully uploaded!</div>
                                <c-button class="mt-5" @click="$router.go">Upload Another</c-button>
                            </div>
                        </c-grid-item>
                    </c-grid>
                </c-grid-item>
            </c-grid>
        </div>
    </c-panel>
</template>

<script lang="ts">
import { defineComponent, inject, ref } from "vue";
import { ImageData } from "coho-ui";
import server from "../server";
import { Database } from "vuebase";
import { Upload } from "../main";
import { CachePolicy } from "vuebase/src/storage";

export default defineComponent({
    name: "Upload",
    setup() {
        const database = inject(Database.InjectionKey);
        const imagePicker = ref();
        const uploadComplete = ref(false);
        const upload = ref(
            database?.collection<Upload>("uploads").new(undefined, {
                image: undefined,
                email: "",
                notes: "",
                dateUploaded:
                    Database.firebase.firestore.FieldValue.serverTimestamp() as Database.firebase.firestore.Timestamp,
            }),
        );

        function uploadImage(file: File, progressCallback: (progress: number) => void): Promise<ImageData | void> {
            return server.uploadImage(
                file,
                "uploads",
                progressCallback,
                CachePolicy.Day,
                { width: 1200, height: 1200 },
                100,
            );
        }

        function saveUpload(): Promise<void> {
            if (upload.value) {
                imagePicker.value.applyCrop();
                return upload.value.save().then(() => {
                    uploadComplete.value = true;
                });
            }
            return Promise.reject();
        }

        return {
            upload,
            uploadComplete,
            imagePicker,
            uploadImage,
            saveUpload,
        };
    },
});
</script>

<style lang="scss">
@import "../../../coho-ui/src/styles/variables.scss";

.success {
    background-color: $color-green;
    color: white;
    padding: 24px;
    border-radius: $border-radius;
    box-shadow: $shadow-sm;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
}
</style>
